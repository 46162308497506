//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalAddList from "@/components/modals/ModalAddList.vue";
import ModalUpdateList from "@/components/modals/ModalUpdateList.vue";
import ModalRemoveList from "@/components/modals/ModalRemoveList.vue";
import ModalListImport from "@/components/modals/ModalListImport.vue";
import MainTitle from "@/components/MainTitle.vue";
import ListsTable from "@/components/List/ListsTable.vue";
import ListsWrapper from "@/components/List/Wrapper.vue";
export default {
  meta: {
    title: "Мои списки"
  },
  data() {
    return {
      refreshKey: 0,
      hasItems: this.$store.state.user.user ? null : false,
      hasItemsLoaded: false
    };
  },
  async created() {
    const result = await this.$store.dispatch("list/getLists", {
      page: 1
    });
    this.hasItems = !!result.items.length;
    this.hasItemsLoaded = true;
  },
  methods: {
    onEdit({
      item,
      context
    }) {
      this.$refs.modalUpdateList.open({
        item,
        onComplete() {
          context.refresh();
        }
      });
    },
    onRemove({
      item,
      context
    }) {
      this.$refs.modalRemoveList.open({
        item,
        onComplete() {
          context.refresh();
        }
      });
    },
    onAdded(item) {
      this.refreshKey++;
      this.hasItems = true;
      setTimeout(() => {
        this.$refs.modalListImport.open({
          item,
          freshList: true
        });
      }, 100);
    },
    onCreateList() {
      this.$access.hasOrThrow("lists");
      this.$refs.modalAddList.open();
    }
  },
  computed: {
    tableKey() {
      return this.refreshKey;
    }
  },
  components: {
    ModalAddList,
    ModalUpdateList,
    ModalRemoveList,
    ModalListImport,
    MainTitle,
    ListsTable,
    ListsWrapper
  }
};